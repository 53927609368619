

import Router from 'next/router'
import styles from '../styles/Home.module.css'
import Head from 'next/head'
import Image from 'next/image'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import { useState } from 'react';


export default function Login() {

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const onSubmit = async function(e, email, password) {
        e.preventDefault();
        
        

        var data = await(await fetch("/api/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "password": password
            })
        })).json()
        if (data.success === false){
            alert(await data.message)
            // console.log(await data)
        } else if (data.success === true){
            localStorage.setItem('Token', data.data.token);
            localStorage.setItem('User', data.data.role);
            Router.push('/')
        }
        
    };

    const redirect = async function(page) {
        Router.push(page)
    };

    


    return (
        <div className={styles.container}>
            <Head>
                <title>Edge Charting Application</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>







            {/* style={{"backgroundImage": "url('bg_img.png')", "backgroundBlendMode": "overlay"}} */}
            <main className={styles.main_flex} >
                <Card style={{ width: '40vh' }}>
                    <Card.Img variant="top" src="/edge_login_image.png" />
                    <Card.Body>
                        <Card.Title>Login</Card.Title>

                        <Form onSubmit={(e)=>onSubmit(e, email, password)}>
                            <Form.Group className="mb-3" id="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="email" id="email" type="email" placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)}/>

                            </Form.Group>

                            <Form.Group className="mb-3" id="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="password" id="password" type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            <Button variant="secondary" style={{ "float": "right" }} onClick={()=>redirect("/signup")}>
                                Sign  up
                            </Button>
                            {/* <button type="button" onClick={() => {
                                throw new Error("Sentry Frontend Error");
                            }}>
                                Throw error
                            </button> */}
                            
                        </Form>



                    </Card.Body>
                    
                </Card>
                <div>
                    Version 1.2
                    </div>

            </main>


        </div>
    )
}
